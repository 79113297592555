/* ! Poster */
.poster{
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
  }
  .poster__img{width: 60%;height: 100%;}
  .poster__img img{object-fit: cover;width: 100%;height: 100%;}
  .poster__content{width: 40%;padding: 50px;}
  .poster__content h3{color: black;}
  
  
  @media (max-width: 768px) {
    .poster{flex-wrap: wrap;height: auto;}
    .poster__img,
    .poster__content{width: 100%;}
    .poster__content h3{color: black;}
  }