/* ! Footer */
.footer{
    background: #333;
    padding: 150px 0;
  }
  .copyright{
    align-items: center;
    justify-content: center;
    display: grid;
    /*border: 1px red;*/
    width: 100%;
  }
  .copyright__text{
    text-align: center;
  }