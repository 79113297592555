.contact {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #000000;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.9)),
    url("../../media/gallery/IMG_4993.JPG") no-repeat center center;
    background-size: cover;
    overflow: hidden;
  align-items: center;
  justify-content: center;
}

.contact__content {
  display: flex;
  flex-direction: row;
  gap: 5px;
  height: 65%;
  width: 520px;
  border: 2px solid #ffffff;
  border-radius: 15px;
  padding: 15px;
  backdrop-filter: blur(5px);
}


  


/* Line */
.line {
  width: 0;
  height: 100%;
  border: 0.5px solid white;
}


/* Info */
.contact-info {
  width: 45%;
  height: 100%;
}

.contact-info div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin-top: 5px;
}

.contact-info p {
  margin-left: 5px;
  padding: 0;
  margin: 5px;
}

/* Form */
.form-container {
  width: 55%;
  height: 100%;
  padding: 10px;
}

.form-container * {
  margin-bottom: 5px;
}

/* Alert */
.contact-alert {
  width: 520px;
}



@media screen and (max-width: 768px) {
  .contact__content {
    flex-direction: column;
    width: 90%;
    height: auto;
    margin-top: 70px;
  }
  .contact {
    height: auto;
    padding-bottom: 25px;
  }
  .line {
    height: 1px;
    width: 100%;
  }
  .contact-info {
    width: 100%;
    height: 50%;
  }
  .form-container {
    width: 100%;
    height: 50%;
  }
  .contact-alert {
    width: 90%;
    top: 65px;
  }
}

@media (min-width: 1024px) {
  .contact-alert {
    width: 45%;
  }
  .contact__content {
    width: 45%;
  }
}