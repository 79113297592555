/* ! Hero Section */
.hero{
    position: relative;
    background-color: #000;
    height: 100vh;
    display: flex; width: 100%; align-items: center; /*align content in  vertical center of the container*/
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.9)),
    url("../../media/gallery/angolo-vini.JPG") no-repeat center center;
    background-size: cover;
    overflow: hidden;
  }
  .hero:after{
    content: ' ';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0px;left: 0px;
    background: #000;
    opacity: 0.4;
  }
  .hero__content{
    padding: 30px;
    width: 100%;
    max-width: 1350px;
    margin: 0 auto;
    z-index: 1;
    position: relative;
  }
  .hero__content h1,
  .hero__content p{
    color: white;
  }
  
  #bg-video{
    position: absolute;
    bottom: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 0;
  }