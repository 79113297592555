.Vino {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    margin-top: 20px;
    border: 0.5px solid grey;
}

.Vino * {
    box-sizing: border-box;
}

.row-st {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 500px;
}

.row-st__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.row-st__title h3 {
    align-self: flex-start;
    text-align: start;
    width: 75%;
    margin-left: 10px;
}
.row-st__title p {
    align-self: flex-end;
    text-align: start;
    width: 75%;
    margin-right: 10px;
}
.row-st img {
    width: 50%;
    height: 100%;
    object-fit: cover;
}

.row-nd {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 500px;
}

.row-nd__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.row-nd__title h3 {
    align-self: flex-start;
    text-align: start;
    width: 75%;
    margin-left: 5px;
}
.row-nd__title p {
    align-self: flex-end;
    text-align: start;
    width: 75%;
    margin-right: 5px;
}
.row-nd img {
    width: 50%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .Vino {height: auto;margin-top: 100px;border: none;}


    .row-st {min-height: 50vh;}
    .row-st__title h3 {
        align-self: center;
        text-align: center;
        width: 100%;
        margin-left: 0;
    }
    .row-st__title p {
        align-self: center;
        text-align: center;
        width: 100%;
        margin-right: 0;
    }
    .row-st {
        flex-direction: column;
    }
    .row-st img {
        width: 100%;
        object-fit: cover;
    }

    .row-nd {min-height: 50vh;margin-top: 40px;}
    .row-nd__title h3 {
        align-self: center;
        text-align: center;
        width: 100%;
        margin-left: 0;
    }
    .row-nd__title p {
        align-self: center;
        text-align: center;
        width: 100%;
        margin-right: 0;
    }
    .row-nd {
        flex-direction: column;
    }
    .row-nd img {
        width: 100%;
        object-fit: cover;
    }
}
    



