$background-color: #333;

$text-color: #000000;
$text-color-secondary: #fff;

$color-secondary: #007bff;



.AppBar-header {
  background: #333;
  
  position: fixed;

  left: 50%;
  transform: translateX(-50%);

  margin-top: 5px;
  border-radius: 20px;
  z-index: 10;

  margin-bottom: 10px;
  max-height: 60px;
  width: 520px;
  padding: 10px 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}


/* AppBar */
.AppBar-info {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 15px;
}

.AppBar-logo_btn {
  height: 75%;
  margin-top: auto;
  margin-bottom: auto;
}

.AppBar-logo {
  height: 48px;
  pointer-events: none;
  margin: auto 0;
  padding: 5px;
  transform: translateY(-2px);
}

.menu_btn {
  display: flex;
  flex-direction: column;
  height: 35px;
  width: 35px;
  padding: 1px;
}

.menu_btn span {
  display: block;
  width: 100%;
  height: 2px;
  background: #fff;
  margin-top: 5px;
  margin-bottom: 5px;
}
.menu_btn a {
  position: absolute;
  text-decoration: none;
  height: 35px;
  width: 35px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .AppBar-header {
    width: 100%;
    border-radius: 0;
    margin-top: 0;
  }
  .AppBar-logo {
    height: 40px;
    border-radius: 12px;
    font-size: 18px;
  }
}

a {
  text-decoration: none;
}

@media (min-width: 1024px) {
  .AppBar-header {
    width: 55%;
  }
}

/* Menu */
#menu {
  position: fixed;
  top: 0;
  left: 50%;

  width: 520px;
  height: 25%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease-in-out;
  margin-top: 5px;
  border-radius: 20px;
  backdrop-filter: blur(5px);
  padding: 10px 10px;
}

.empty {
  height: 65px;
  width: 100%;
}

.menu__content {
  width: 100%;
  margin-top: 10px;
  height: 100%;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.menu__content ul {
  align-self: center;
  justify-self: center;
  text-align: left;
  margin:0;
  padding:0;
  margin-left: 10px;
}

.menu__content ul li {
  margin-top: 5px;
  list-style: none;
  margin-bottom: 10px;
}

.menu__content ul li a {
  color: #fff;
  font-size: 18px;
  text-decoration: none;
}


@media (max-width: 768px) {
  .menu__content ul li a {
    text-decoration: underline;
    text-decoration-color: $color-secondary;
    text-decoration-thickness: 1px;
  }
}

@media (min-width: 769px) {
  .underline-animation {
    display: inline-block;
    position: relative;
    color: $color-secondary;
  }
  
  .underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $color-secondary;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}

.menu__gallery {
  height: 100%;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.menu__gallery * {
  height: 100px;
}



@media (min-width: 1024px) {
  #menu {
    width: 55%;
  }
}
@media (max-width: 768px) {
  #menu {
    width: 100%;
    margin-top: 0;
  }
}

.menu--open {
  transform: translate(-50%, 0%);
}
.menu--close {
  transform: translate(-50%, -100%);
}
