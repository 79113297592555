.infolegna {
  min-height: 100vh;
  display: flex;
  margin-top: 20px;
    flex-direction: column;
    align-items: center;
}


/* Title */
.title-container {
    display: flex;
    flex-direction: column;
    margin: 15px;
}
.title-container h3 {
    margin: 0;
}

.description {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 40px;
    font-weight: 400;
    color: grey;
    width: 55%;
}

@media  (max-width: 768px) {
    .description {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}

/* Cards */
.infolegna__content {
    display: flex;
    flex-wrap: wrap;
    width: 95%;
}
.infolegna__card {
    margin: 5px;
    width: 300px;
}
.infolegna__card__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .infolegna__content {
        flex-direction: column;
        align-items: center;
        height: auto;
    }
}
    






/* ! Grid */
.grid {
  display: flex;
  max-width: 1350px;
  padding: 0 30px;
  margin: 0 auto;
}
.grid .col {
  width: 100%;
}

.col-desc {
    size: 20px;
}

@media (max-width: 768px) {
  .grid {
    flex-wrap: wrap;
  }
}
