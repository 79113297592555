:root {
  /* solid */

  --eerie-black-1: hsl(240, 2%, 13%);
  --eerie-black-2: hsl(240, 2%, 12%);
  --smoky-black: #121212;
  --white-1: hsl(0, 0%, 100%);
  --white-2: #fafafa;
  --light-gray: hsl(0, 0%, 84%);

  /**
   * typography
   */

  /* font-family */
  --ff-poppins: "Poppins", sans-serif;

  /* font-size */
  --fs-1: 24px;
  --fs-2: 18px;
  --fs-3: 17px;
  --fs-4: 16px;
  --fs-5: 15px;
  --fs-6: 14px;
  --fs-7: 13px;
  --fs-8: 11px;

  /* font-weight */
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;

  /**
   * shadow
   */

  --shadow-1: -4px 8px 24px hsla(0, 0%, 0%, 0.25);
  --shadow-2: 0 16px 30px hsla(0, 0%, 0%, 0.25);
  --shadow-3: 0 16px 40px hsla(0, 0%, 0%, 0.25);
  --shadow-4: 0 25px 50px hsla(0, 0%, 0%, 0.15);
  --shadow-5: 0 24px 80px hsla(0, 0%, 0%, 0.25);
}

body {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  padding: 0 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
p {
  color: white;
  padding-bottom: 30px;
}
p {
  line-height: 28px;
  color: #666;
  font-weight: 300px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.big-text {
  font-size: 70px;
  font-weight: 900;
}
.mid-text {
  font-size: 40px;
}
.normal-text {
  font-size: 20px;
}
.normal-text1 {
  font-size: 16px;
}

.intro-text {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .big-text {
    font-size: 40px;
  }
}

.button {
  padding: 16px 22px;
  background: #316bff;
  display: inline-block;
  border-radius: 10px;
}

/* Grid */
.grid{display: flex;max-width: 1350px; padding: 0 30px;margin:0 auto;/*border: 1px solid red;*/}
.grid .col{width: 100%;}

@media (max-width: 768px) {
  .grid{flex-wrap: wrap;}
}

/* ! Helpers */
.mt-000 {
  margin-top: 5px;
}
.mr-0 {
  margin-right: 10px;
}
.mb-1 {
  margin-bottom: 15px;
}
.mb-0 {
  margin-bottom: 5px;
}
.mt-0 {
  margin-top: 5px;
}
.mt-00 {
  margin-top: 10px;
}
.mt-1 {
  margin-top: 50px;
}
.mt-2 {
  margin-top: 100px;
}
.mt-3 {
  margin-top: 150px;
}
.color666 {
  color: #666;
}
.tw {
  color: #fff;
}
.tb {
  color: black;
}
.p0px {
  padding: 0;
}
.pb15px {
  padding-bottom: 15px;
}

@media (max-width: 768px) {
  .mt-sma-0 {
    margin-top: 0;
  }
  .mb-sma-0 {
    margin-bottom: 0;
  }
  .mt-sma-foot {
    margin-top: 15px;
  }
}
@media (max-width: 965px) {
  .fs {
    font-size: 50px;
  }
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
